import React from "react";
var __jsx = React.createElement;
import Link from "next/link";
import { useTranslation } from "../util/useTranslation";

var About = function About() {
  var _useTranslation = useTranslation(),
      translate = _useTranslation.t;

  return __jsx("main", {
    className: "about-section",
    id: "about"
  }, __jsx("section", {
    className: "start_home demo3"
  }, __jsx("div", {
    className: "home-top-shape"
  }, __jsx("img", {
    src: "/assets/banner_bg.png"
  })), __jsx("div", {
    className: "bubbles-animate"
  }, __jsx("div", {
    className: "bubble b_one"
  }), __jsx("div", {
    className: "bubble b_two"
  }), __jsx("div", {
    className: "bubble b_three"
  }), __jsx("div", {
    className: "bubble b_four"
  }), __jsx("div", {
    className: "bubble b_five"
  }), __jsx("div", {
    className: "bubble b_six"
  })), __jsx("div", {
    className: "banner_top d-flex align-items-center justify-content-center"
  }, __jsx("div", {
    className: "mainContainer"
  }, __jsx("div", {
    className: "row"
  }, __jsx("div", {
    className: "col-md-6 start-home-content"
  }, __jsx("h1", null, " ", translate("about-title")), __jsx("p", null, " ", translate("about-privileges")), __jsx("p", null, translate("about-description")), __jsx("div", {
    className: "banner-btns"
  }, __jsx(Link, {
    href: "https://me.appscoper.com/#/register",
    passHref: true
  }, __jsx("button", {
    className: "btn theme-btn d-inline-block"
  }, translate("about-btn"))))), __jsx("div", {
    className: "col-md-6 start-home-img"
  }, __jsx("img", {
    className: "img-fluid",
    src: "/assets/66260-removebg.png",
    alt: "Img",
    style: {
      width: "900px",
      height: "auto"
    }
  })))))));
};

export default About;