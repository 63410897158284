import React from "react";
var __jsx = React.createElement;
import { useTranslation } from "../util/useTranslation";
import Link from "next/link";

var Pricing = function Pricing() {
  var _useTranslation = useTranslation(),
      translate = _useTranslation.t;

  return __jsx("section", {
    id: "pricing",
    className: "section-block d-flex align-items-center justify-content-center",
    "data-scroll-index": "4"
  }, __jsx("div", {
    className: "mainContainer"
  }, __jsx("div", {
    className: "row"
  }, __jsx("div", {
    className: "col-md-5"
  }, __jsx("div", {
    className: "section-header-style2"
  }, __jsx("h2", null, translate("pricing_title2")), __jsx("p", null, translate("pricing_title3")))), __jsx("div", {
    className: "col-md-7"
  }, __jsx("div", {
    className: "tab-content"
  }, __jsx("div", {
    id: "monthly",
    className: "tab-pane fade in active show"
  }, __jsx("div", {
    className: "row"
  }, __jsx("div", {
    className: "col-md-6"
  }, __jsx("div", {
    className: "pricing-card"
  }, __jsx("header", {
    className: "card-header"
  }, __jsx("h4", null, "Growth"), __jsx("span", {
    className: "card-header-price"
  }, __jsx("span", {
    className: "price-num"
  }, translate("plan1-price")), __jsx("span", {
    className: "price-date"
  }, " ", translate("plan1-date"))), __jsx("div", {
    className: "shape-bottom"
  }, __jsx("img", {
    src: "/assets/price-shape.svg",
    alt: "shape",
    className: "bottom-shape img-fluid"
  }))), __jsx("div", {
    className: "card-body"
  }, __jsx("ul", null, __jsx("li", null, __jsx("span", {
    className: "fas fa-check"
  }), translate("plan1_feature1")), __jsx("li", null, __jsx("span", {
    className: "fas fa-check"
  }), translate("plan1_feature2")), __jsx("li", null, __jsx("span", {
    className: "fas fa-check"
  }), translate("plan1_feature3")), __jsx("li", null, __jsx("span", {
    className: "fas fa-check"
  }), translate("plan1_feature4")), __jsx("li", null, __jsx("span", {
    className: "fas fa-check"
  }), translate("plan1_feature5")), __jsx("li", null, __jsx("span", {
    className: "fas fa-check"
  }), translate("plan1_feature6")), __jsx("li", null, __jsx("span", {
    className: "fas fa-check"
  }), translate("plan1_feature7")), __jsx("li", null, __jsx("span", {
    className: "fas fa-check"
  }), translate("plan1_feature9")), __jsx("li", null, __jsx("span", {
    className: "fas fa-check"
  }), translate("plan1_feature10")), __jsx("li", null, __jsx("span", {
    className: "fas fa-check"
  }), translate("plan1_feature11")), __jsx("li", null, __jsx("span", {
    className: "fas fa-check"
  }), translate("plan1_feature12"))), __jsx("a", {
    href: "https://me.appscoper.com/#/register",
    type: "button",
    className: "btn btn-sm btn-block"
  }, translate("get_started"))))), __jsx("div", {
    className: "col-md-6"
  }, __jsx("div", {
    className: "pricing-card top-35"
  }, __jsx("header", {
    className: "card-header"
  }, __jsx("h4", null, "Enterprise"), __jsx("span", {
    className: "card-header-price"
  }, __jsx("span", {
    className: "price-num"
  }, " ", translate("plan2-price"))), __jsx("div", {
    className: "shape-bottom"
  }, __jsx("img", {
    src: "/assets/price-shape.svg",
    alt: "shape",
    className: "bottom-shape img-fluid"
  }))), __jsx("div", {
    className: "card-body"
  }, __jsx("p", {
    className: "text-dark fw-bold"
  }, translate("plan2_feature1")), __jsx("ul", null, __jsx("li", null, __jsx("span", {
    className: "fas fa-check"
  }), translate("plan2_feature10")), __jsx("li", null, __jsx("span", {
    className: "fas fa-check"
  }), translate("plan2_feature2")), __jsx("li", null, __jsx("span", {
    className: "fas fa-check"
  }), translate("plan2_feature3")), __jsx("li", null, __jsx("span", {
    className: "fas fa-check"
  }), translate("plan2_feature4")), __jsx("li", null, __jsx("span", {
    className: "fas fa-check"
  }), translate("plan2_feature5")), __jsx("li", null, __jsx("span", {
    className: "fas fa-check"
  }), translate("plan2_feature6")), __jsx("li", null, __jsx("span", {
    className: "fas fa-check"
  }), translate("plan2_feature7")), __jsx("li", null, __jsx("span", {
    className: "fas fa-check"
  }), translate("plan2_feature11"))), __jsx("a", {
    href: "/#contact",
    type: "button",
    className: "btn btn-sm btn-block"
  }, translate("contact_sale"))))))))))));
};

export default Pricing;