import _defineProperty from "/opt/bitnami/apache/htdocs/Appscoper-website/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
var __jsx = React.createElement;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useState } from "react";
import Image from "next/image";
import { useTranslation } from "../util/useTranslation";
import { contact } from "../pages/api/contact";
import { toast } from "react-toastify";

var Contact = function Contact() {
  var _useTranslation = useTranslation(),
      translate = _useTranslation.t;

  var _useState = useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  }),
      formData = _useState[0],
      setFormData = _useState[1];

  var _useState2 = useState({}),
      errors = _useState2[0],
      setErrors = _useState2[1];

  var validateForm = function validateForm() {
    var errors = {};

    if (!formData.name) {
      errors.name = translate("name_required");
    } else {
      errors.name = "";
    }

    if (!formData.email) {
      errors.email = translate("email_required");
    } else if (!isValidEmail(formData.email)) {
      errors.email = translate("invalid_email");
    } else {
      errors.email = "";
    }

    if (!formData.phone) {
      errors.phone = translate("phone_required");
    } else {
      errors.phone = "";
    }

    if (!formData.message) {
      errors.message = translate("message_required");
    } else {
      errors.message = "";
    }

    setErrors(errors);
    return Object.values(errors).every(function (error) {
      return !error;
    });
  };

  var isValidEmail = function isValidEmail(email) {
    var emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailPattern.test(email);
  };

  var submit = function submit(e) {
    e.preventDefault();

    if (validateForm()) {
      contact(JSON.stringify(formData)).then(function () {
        toast.success(translate("send_successfully"));
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: ""
        });
      })["catch"](function (error) {
        return console.log(error);
      });
    }
  };

  var handleInputChange = function handleInputChange(e) {
    setFormData(_objectSpread(_objectSpread({}, formData), {}, _defineProperty({}, e.target.name, e.target.value))); // Clear the corresponding error when typing

    setErrors(_objectSpread(_objectSpread({}, errors), {}, _defineProperty({}, e.target.name, "")));
  };

  return __jsx("div", {
    className: "d-flex align-items-center justify-content-center"
  }, __jsx("section", {
    id: "contact",
    className: "section-block mainContainer",
    style: {
      fontSize: "14px"
    }
  }, __jsx("div", {
    className: "bubbles-animate"
  }, __jsx("div", {
    className: "bubble b_one"
  }), __jsx("div", {
    className: "bubble b_two"
  }), __jsx("div", {
    className: "bubble b_three"
  }), __jsx("div", {
    className: "bubble b_four"
  }), __jsx("div", {
    className: "bubble b_five"
  }), __jsx("div", {
    className: "bubble b_six"
  })), __jsx("div", {
    className: "row d-flex justify-content-between"
  }, __jsx("div", {
    className: "col-md-5"
  }, __jsx("div", {
    className: "section-header-style2"
  }, __jsx("h2", null, translate("find_us")), __jsx("p", null, translate("find_us_paragraph"))), __jsx("div", {
    className: "contact-details"
  }, __jsx("div", {
    className: "contact-block"
  }, __jsx("h4", null, translate("office_location")), __jsx("div", {
    className: "contact-block-side d-flex align-items-center"
  }, __jsx("i", {
    className: "flaticon-route"
  }), __jsx("p", null, __jsx("span", null, translate("street_name"), " "), __jsx("span", null, translate("city"))))), __jsx("div", {
    className: "contact-block"
  }, __jsx("h4", null, translate("support-email")), __jsx("div", {
    className: "contact-block-side d-flex align-items-center"
  }, __jsx("i", {
    className: "flaticon-paper-plane-1"
  }), __jsx("p", null, __jsx("a", {
    href: "mailto:support@appscoper.com"
  }, "support@appscoper.com")))), __jsx("div", {
    className: "contact-block"
  }, __jsx("h4", null, translate("contact-email")), __jsx("div", {
    className: "contact-block-side d-flex align-items-center"
  }, __jsx("i", {
    className: "flaticon-paper-plane-1"
  }), __jsx("p", null, __jsx("a", {
    href: "mailto:hello@appscoper.com"
  }, "hello@appscoper.com")))))), __jsx("div", {
    className: "col-md-7"
  }, __jsx("div", {
    className: "contact-shape"
  }, __jsx(Image, {
    src: "/assets/contact-form.png",
    width: 100,
    height: 100,
    alt: "contact "
  })), __jsx("div", {
    className: "contact-form-block"
  }, __jsx("div", {
    className: "section-header-style2"
  }, __jsx("h2", null, translate("contact_us")), __jsx("p", null, translate("contact_us_desc"))), __jsx("form", {
    className: "contact-form",
    onSubmit: submit
  }, __jsx("div", null, __jsx("input", {
    type: "text",
    name: "name",
    className: "form-control",
    placeholder: translate("name_placeholder"),
    value: formData.name,
    onChange: handleInputChange
  }), errors.name && __jsx("div", {
    className: "error-message"
  }, errors.name)), __jsx("div", null, __jsx("input", {
    name: "email",
    className: "form-control",
    placeholder: translate("email_placeholder"),
    value: formData.email,
    onChange: handleInputChange
  }), errors.email && __jsx("div", {
    className: "error-message"
  }, errors.email)), __jsx("div", null, __jsx("input", {
    type: "tel",
    name: "phone",
    className: "form-control",
    placeholder: translate("phone_placeholder"),
    value: formData.phone,
    onChange: handleInputChange
  }), errors.phone && __jsx("div", {
    className: "error-message"
  }, errors.phone)), __jsx("div", null, __jsx("textarea", {
    name: "message",
    className: "form-control",
    placeholder: translate("message_placeholder"),
    value: formData.message,
    onChange: handleInputChange
  }), errors.message && __jsx("div", {
    className: "error-message"
  }, errors.message)), __jsx("button", {
    className: "btn theme-btn",
    type: "submit"
  }, translate("send_message"))))))));
};

export default Contact;