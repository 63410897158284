import React from "react";
var __jsx = React.createElement;
import { useTranslation } from "../util/useTranslation";

var Features = function Features() {
  var _useTranslation = useTranslation(),
      translate = _useTranslation.t;

  var icons = ["flaticon-smartphone", "flaticon-layers", "flaticon-target", "flaticon-users", "flaticon-link"];
  return __jsx("section", {
    id: "features",
    className: "section-block features-style d-flex align-items-center justify-content-center",
    "data-scroll-index": "1"
  }, __jsx("div", {
    className: "mainContainer"
  }, __jsx("div", {
    className: "section-header text-center my-5"
  }, __jsx("h2", null, translate("features-title")), __jsx("p", null, translate("features-subtitle"))), __jsx("div", {
    className: "row"
  }, __jsx("div", {
    className: "col-md-6"
  }, __jsx("div", {
    className: "img-box text-center"
  }, __jsx("img", {
    src: "/assets/data-hero.png",
    className: "img-fluid",
    alt: "Img"
  }))), __jsx("div", {
    className: "col-md-6"
  }, translate("features-array") && translate("features-array").length > 0 && translate("features-array").map(function (feature, index) {
    return __jsx("div", {
      className: "feature-block",
      key: index
    }, __jsx("p", {
      className: "feq-icon-box"
    }, __jsx("div", {
      className: "faq-icon"
    }, __jsx("i", {
      className: "flaticon ".concat(icons[index % translate("features-array").length])
    })), __jsx("div", {
      className: "featured-content"
    }, __jsx("h5", null, feature.title), __jsx("p", null, feature.description))));
  })))));
};

export default Features;