import React from "react";
var __jsx = React.createElement;
import { useTranslation } from "../util/useTranslation";

var HowItWorks = function HowItWorks() {
  var _useTranslation = useTranslation(),
      translate = _useTranslation.t;

  return __jsx("section", {
    id: "how-it-works",
    className: "section-block how-it-works d-flex align-items-center justify-content-center",
    "data-scroll-index": "2"
  }, __jsx("div", {
    className: "mainContainer"
  }, __jsx("div", {
    className: "section-header text-center my-5text-center my-5"
  }, __jsx("h2", null, translate("how-works")), __jsx("p", null, translate("how-works-subtitle"))), __jsx("div", {
    className: "row "
  }, __jsx("div", {
    className: "col-md-6"
  }, translate("steps-array") && translate("steps-array").length > 0 && translate("steps-array").map(function (step, index) {
    return __jsx("div", {
      className: "description-block",
      key: "step".concat(index)
    }, __jsx("div", {
      className: "inner-box"
    }, __jsx("div", {
      className: "step_num"
    }, __jsx("img", {
      src: "/assets/".concat(index + 1, ".png")
    })), __jsx("h3", null, step.title), __jsx("p", null, step.description)));
  })), __jsx("div", {
    className: "col-md-6  "
  }, __jsx("div", {
    className: "img-box d-flex justify-content-center "
  }, __jsx("img", {
    src: "/assets/3515462.jpg",
    alt: "Img",
    style: {
      width: "500px",
      height: "auto"
    }
  }))))));
};

export default HowItWorks;