import React from "react";
var __jsx = React.createElement;
import { useTranslation } from "../util/useTranslation";

var WhyAppscoper = function WhyAppscoper() {
  var _useTranslation = useTranslation(),
      translate = _useTranslation.t;

  return __jsx("section", {
    id: "why-appscoper",
    className: "section-block d-flex align-items-center justify-content-center",
    "data-scroll-index": "2",
    style: {
      fontSize: "14px"
    }
  }, __jsx("div", {
    className: "mainContainer"
  }, __jsx("div", {
    className: "row "
  }, __jsx("div", {
    className: "col-md-6"
  }, __jsx("div", {
    className: "section-header-style2"
  }, __jsx("h2", null, translate("why_appscoper?"))), __jsx("ul", {
    className: "list-checked"
  }, __jsx("li", null, __jsx("i", {
    className: "fas fa-check"
  }), " ", translate("why_appscoper1")), __jsx("li", null, __jsx("i", {
    className: "fas fa-check"
  }), " ", translate("why_appscoper2")), __jsx("li", null, __jsx("i", {
    className: "fas fa-check"
  }), translate("why_appscoper3")), __jsx("li", null, __jsx("i", {
    className: "fas fa-check"
  }), translate("why_appscoper4"))), __jsx("h3", null, translate("ready_to_boost"), " ", __jsx("a", {
    href: "https://me.appscoper.com/#/login",
    className: "sign_up"
  }, translate("sign_up_now")), " ")), __jsx("div", {
    className: "col-md-6 pt-5 d-flex align-items-center"
  }, __jsx("div", {
    className: "img-box pt-5"
  }, __jsx("img", {
    src: "/assets/why2.png",
    alt: "Img",
    className: "mt-5"
  }))))));
};

export default WhyAppscoper;