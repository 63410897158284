import React from "react";
var __jsx = React.createElement;
import Layout from "../components/Layout";
import Contact from "../components/Contact";
import FAQS from "../components/FAQS";
import Pricing from "../components/Pricing";
import Features from "../components/Features";
import HowItWorks from "../components/How-it-works";
import About from "../components/about";
import WhyAppscoper from "../components/WhyAppscoper";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var Home = function Home() {
  return __jsx("main", null, __jsx(ToastContainer, {
    style: {
      fontSize: "14px"
    }
  }), __jsx(About, null), __jsx(WhyAppscoper, null), __jsx(Features, null), __jsx(HowItWorks, null), __jsx(Pricing, null), __jsx(FAQS, null), __jsx(Contact, null));
};

Home.getLayout = function getLayout(page) {
  return __jsx(Layout, {
    title: "home"
  }, page);
};

export default Home;