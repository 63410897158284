import React from "react";
var __jsx = React.createElement;
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "../util/useTranslation";

var FAQS = function FAQS() {
  var _useTranslation = useTranslation(),
      translate = _useTranslation.t;

  return __jsx(React.Fragment, null, __jsx("section", {
    id: "faqs",
    className: "section d-flex align-items-center justify-content-center",
    "data-scroll-index": "6"
  }, __jsx("div", {
    className: "mainContainer"
  }, __jsx("div", {
    className: "section-header"
  }, __jsx("h2", null, translate("faq")), __jsx("p", null, translate("faq_describtion"))), __jsx("div", {
    className: "row"
  }, __jsx("div", {
    className: "col-md-6"
  }, __jsx("div", {
    className: "img-box"
  }, __jsx("img", {
    src: "/assets/faqs.jpg",
    alt: "Img",
    className: "img-fluid"
  }))), __jsx("div", {
    className: "col-md-6"
  }, __jsx("div", {
    className: "accordion",
    id: "accordionExample"
  }, __jsx("div", {
    className: "card"
  }, __jsx("div", {
    className: "card-header",
    id: "headingOne"
  }, __jsx("h2", {
    className: "mb-0"
  }, __jsx("button", {
    className: "btn btn-link",
    type: "button",
    "data-bs-toggle": "collapse",
    "data-bs-target": "#collapseOne",
    "aria-expanded": "true",
    "aria-controls": "collapseOne"
  }, translate("faq1")))), __jsx("div", {
    id: "collapseOne",
    className: "collapse show",
    "aria-labelledby": "headingOne",
    "data-bs-parent": "#accordionExample"
  }, __jsx("div", {
    className: "card-body"
  }, translate("faq1_answer")))), __jsx("div", {
    className: "card"
  }, __jsx("div", {
    className: "card-header",
    id: "headingTwo"
  }, __jsx("h2", {
    className: "mb-0"
  }, __jsx("button", {
    className: "btn btn-link collapsed",
    type: "button",
    "data-bs-toggle": "collapse",
    "data-bs-target": "#collapseTwo",
    "aria-expanded": "false",
    "aria-controls": "collapseTwo"
  }, translate("faq2")))), __jsx("div", {
    id: "collapseTwo",
    className: "collapse",
    "aria-labelledby": "headingTwo",
    "data-bs-parent": "#accordionExample"
  }, __jsx("div", {
    className: "card-body"
  }, translate("faq2_answer")))), __jsx("div", {
    className: "card"
  }, __jsx("div", {
    className: "card-header",
    id: "headingThree"
  }, __jsx("h2", {
    className: "mb-0"
  }, __jsx("button", {
    className: "btn btn-link collapsed",
    type: "button",
    "data-bs-toggle": "collapse",
    "data-bs-target": "#collapseThree",
    "aria-expanded": "false",
    "aria-controls": "collapseThree"
  }, translate("faq3")))), __jsx("div", {
    id: "collapseThree",
    className: "collapse",
    "aria-labelledby": "headingThree",
    "data-bs-parent": "#accordionExample"
  }, __jsx("div", {
    className: "card-body"
  }, translate("faq3_answer")))), __jsx("div", {
    className: "card"
  }, __jsx("div", {
    className: "card-header",
    id: "headingFour"
  }, __jsx("h2", {
    className: "mb-0"
  }, __jsx("button", {
    className: "btn btn-link collapsed",
    type: "button",
    "data-bs-toggle": "collapse",
    "data-bs-target": "#collapseFour",
    "aria-expanded": "false",
    "aria-controls": "collapseFour"
  }, translate("faq4")))), __jsx("div", {
    id: "collapseFour",
    className: "collapse",
    "aria-labelledby": "headingFour",
    "data-bs-parent": "#accordionExample"
  }, __jsx("div", {
    className: "card-body"
  }, translate("faq4_answer")))), __jsx("div", {
    className: "card"
  }, __jsx("div", {
    className: "card-header",
    id: "headingFive"
  }, __jsx("h2", {
    className: "mb-0"
  }, __jsx("button", {
    className: "btn btn-link collapsed",
    type: "button",
    "data-bs-toggle": "collapse",
    "data-bs-target": "#collapseFive",
    "aria-expanded": "false",
    "aria-controls": "collapseFive"
  }, translate("faq5")))), __jsx("div", {
    id: "collapseFive",
    className: "collapse",
    "aria-labelledby": "headingFive",
    "data-bs-parent": "#accordionExample"
  }, __jsx("div", {
    className: "card-body"
  }, translate("faq5_answer"))))))))));
};

export default FAQS;